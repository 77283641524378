<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <Avatar size="large" class="primary" icon="pi pi-sliders-v" />
                                        </div>
                                        <div class="ml-2">
                                            <div class="text-2xl text-primary font-bold">Institution Programme Details</div>
                                            <div class="text-sm text-500"> Details of record </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <hr />
            </template>
            <section class="page-section " >
                <div class="container">
                    <div class="grid ">
                        <div class="col comp-grid" >
                            <div class="">
                                <div >
                                    <div class="relative-position" style="min-height:100px">
                                        <template v-if="!loading && item">
                                            <div class="row q-col-gutter-x-md">
                                                <div class="col-12">
                                                    <div class="grid align-items-center">
                                                        <div class="col">
                                                            Institution: 
                                                        </div>
                                                        <div class="col font-bold">{{ item.caps_institution_id }}</div>
                                                    </div>
                                                    <hr />
                                                    <div class="grid align-items-center">
                                                        <div class="col">
                                                            Programme: 
                                                        </div>
                                                        <div class="col font-bold">{{ item.caps_programme_id }}</div>
                                                    </div>
                                                    <hr />
                                                    <div class="flex justify-content-end">
                                                        <SplitButton class="p-button p-button-raised p-button-text p-button-sm" :model="getActionMenuModel(item)">
                                                            <i></i>
                                                        </SplitButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="loading">
                                            <div>
                                                <table class="p-datatable-table w-full">
                                                    <tbody>
                                                        <tr v-for="n in 5" :key="n">
                                                            <td class="text-left"  style="width:30%">
                                                                <Skeleton height="2rem" class="m-2" />
                                                            </td>
                                                            <td class="text-left"  style="width:70%">
                                                                <Skeleton height="2rem" class="m-2" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="p-3 text-center">
                                                <ProgressSpinner style="width:50px;height:50px" />
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>
<script>
	import { defineAsyncComponent, ref } from "vue";
	import { PageMixin } from "../../mixins/page.js";
	import { ViewPageMixin } from "../../mixins/viewpage.js";
	import { mapActions, mapGetters, mapState } from "vuex";
	export default {
		name: 'viewCapsinstitutionprogrammesPage',
		components: {
		},
		mixins: [PageMixin, ViewPageMixin ],
		props: {
			pageName: {
				type : String,
				default : 'caps_institution_programmes',
			},
			idName: {
				type: String,
				default: 'caps_institution_programme_id',
			},
			routeName : {
				type : String,
				default : 'caps_institution_programmesview',
			},
			pagePath: {
				type : String,
				default : 'caps_institution_programmes/view',
			},
			apiPath: {
				type : String,
				default : 'caps_institution_programmes/view',
			},
		},
		data() {
            return {
				item: {
					default :{
					},
				},
			}
		},
		computed: {
			pageTitle:{
				get: function () {
					return "Institution Programme Details"
				}
			},
			currentRecord: {
				get: function () {
					return this.$store.getters["caps_institution_programmes/currentRecord"];
				},
				set: function (value) {
					this.$store.commit("caps_institution_programmes/setCurrentRecord", value);
				},
			},
		},
		meta() {
			return {
				title: this.pageTitle
			}
		},
		methods: {
			...mapActions("caps_institution_programmes", [ "fetchRecord", "deleteRecord"]),
				getActionMenuModel(data){
					return [
  {
    label: "Edit",
    to: `/caps_institution_programmes/edit/${data.caps_institution_programme_id}`,
    icon: "pi pi-pencil"
  },
  {
    label: "Delete",
    command: (event) => { this.deleteItem(data.caps_institution_programme_id) },
    icon: "pi pi-minus-circle"
  }
]
				},
		},
		watch: {
			$route (to, from){
				//only fetch data when navigated to this page
				if(to.name == this.routeName){
					//this.load();
				}
			},
		},
		async mounted() {
		},
		async created() {
		},
	};
</script>
<style scoped>
</style>
